.clearfixblog::after{
  content: '';
  display: block;
  clear: both;
}
.text-d-none{
  color: black;
  text-decoration: none;
}
.p-1r{
  padding-left: 1rem;
}
.blogContent{
    width: 90%;
    margin: 30px auto 30px;
    text-align: left;
  }
.blogContent .main-content{
    width: 70%;
    float: left;
  }
  .blogContent .single-content{
    width: 70%;
    margin: auto;
  }
  .blogContent .main-content .article{
    width: 95%;
    height: 270px;
    margin: 20px auto;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background: whitesmoke;
    position: relative;
  }
  .blogContent .single-content .single{
    width: 95%;
    margin: 20px auto;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background: whitesmoke;
    position: relative;
  }
  .blogContent .main-content .article img{
    width: 40%;
    height: 100%;
    float: left;
    object-fit: cover;
  }
  .blogContent .single-content .single img{
    width: 100%;
    max-width: 500px;
    display: block;
    margin: auto;
    height: 100%;
    max-height: 750px;
    object-fit: cover;
  }
  .blogContent .main-content .article .post-preview{
    width: 60%;
    float: right;
  }
  .blogContent .single-content .single .post-preview{
    width: 100%;
    padding-bottom: 20px;
  }
  .blogContent .main-content .article .learn-more{
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: black;
  }
  .blogContent .main-content .article .learn-more:hover{
    color: white;
  }

  .blogContent .sidebar{
    width: 30%;
    float: left;
  }
  .blogContent .sidebar .section{
    background: whitesmoke;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  .blogContent .sidebar .section.topics{
    margin-top: 20px;
  }
  .blogContent .sidebar .section.topics ul{
    margin: 0px;
    padding: 0px;
    list-style: none;
    border-top: 1px solid #e0e0e0;;
  }
  .blogContent .sidebar .section.topics ul li{
    display: block;
    padding: 15px 0px 15px 0px;
    text-decoration: none;
    border-bottom: 1px solid #e0e0e0;
    color: black;
    transition: all 0.3s;
  }
  .blogContent .sidebar .section.topics ul li:hover{
    padding-left: 10px;
    transition: all 0.3s;
    color: gray;
    
  }
  .text-input{
    padding: .7rem 1rem;
    
    display: block;
    width: 80%;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    outline: none;
    color: #444;
    line-height: 1.5rem;
    font-size: 1.2em;
    font-family: 'lora', serif;
  }
  .formBlock .text-input{
    margin: auto;
  }
  @media only screen and (max-width: 945px){
    .blogContent{
      width: 90%;
    }
    .blogContent .main-content{
      width: 100%;
    }
    .blogContent .main-content .article{
      width: 100%;
    }
    .blogContent .sidebar{
      width: 100%;
      padding: 10px;
    }
    .blogContent .sidebar .section.search{
      margin-top: 30px;
    }
  }
  @media only screen and (max-width: 800px){
    .blogContent .main-content .article{
      height: 260px;
    }
  }
  @media only screen and (max-width: 600px){
    .blogContent .main-content .article{
      height: auto;
    }
    .blogContent .main-content .article img{
      width: 100%;
    }
    .blogContent .main-content .article .post-preview{
      width: 100%;
    }
    .blogContent .main-content .article .learn-more{
      position: relative;
      display: block;
      width: 75%;
      text-align: center;
      margin: auto;
      margin-bottom: 10px;
      bottom: 0;
      right: 0;
    }
  }

