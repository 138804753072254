.slider {
  position: relative;
  background-color: rgba(255, 174, 38, 0.75);
  width: 100%;
  height: 80vh;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  overflow: hidden;

  i {
    font-size: 3vw;
  }
}

.logo-img {
  height: 50px;
  width: auto;
  padding: 5px 5px;
}

.slide {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &:hover .dot,
  &:hover %btn-styles {
    opacity: 1;
  }
}

.manual {
  display: block;
}

.auto {
  display: none;
}

%btn-styles {
  position: absolute;
  top: 50%;
  padding: 20px;
  border-radius: 50%;
  background: rgba($color: #000000, $alpha: 0.1);;
  border: none;
  outline: none;
  opacity: 0;
  transition: .5s;

  &:hover {
    background: rgba($color: #000000, $alpha: 0.4);
    cursor: pointer;

    i {
      color: whitesmoke;
    }
  }
}

#goleft {
  margin-left: 5%;
  left: 0;
  @extend %btn-styles;
}

#goright {
  margin-right: 5%;
  right: 0;
  @extend %btn-styles;
}

@keyframes moving {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.dots {
  position: absolute;
  bottom: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot {
  padding: 8px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgba(255, 255, 255, 0.9);

  opacity: 0;
  transition: .5s;
}

.dot2 {
  background: white;
}

@media (max-width: 500px) {
  .slider {
    height: 50vh;
  }
  .hide{
    display: none;
  }
  .logo-img {
    height: 40px;
  }
}

@media (max-width: 380px) {
  .slider {
    height: 35vh;
  }
}