body{
  text-align: center;
  margin: 0px;
  padding: 0px;

  /*FUENTES*/
  font-family: 'Georgia, Times, Times New Roman, serif';
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  font-display: swap;
}
.avisoLegal {
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;     /* Sólo WebKit -NO DOCUMENTADO */

  -ms-hyphens: auto;          /* Guiones para separar en sílabas */
  -moz-hyphens: auto;         /*  depende de lang en <html>      */
  -webkit-hyphens: auto;
  hyphens: auto;
}
th{
  white-space:nowrap;
}

.headerSpace{
  height: 100px;
  width: 100%;
  background: whitesmoke;
}
.titlebg{
  border-radius: 0 0 250px 250px;
  background-color: rgba(255,174,38,0.5);
}
.container{
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
}
.map{
  border-radius: 0 50px 50px 0;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  margin-bottom: 30px;
}
#myMap{
    width:600px;
}
.map .map-location{
  text-align: left;
  width: 30%;
  margin-left: 15px;
}

.container .card{
  max-width: 350px;
  background-color: rgb(255,214,146);
  border-radius: 8px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  width: 300px;
  overflow: hidden;
  margin: 20px;
  text-align: center;
  transition: all 0.25s;
  display: flex; 
  flex-direction: column;
}

.container .card img{
  clip-path: polygon(0 0,100% 0, 100% 85%,0 100%);
  width: 100%;
  height: 300px;
  object-fit: cover;
}


.container .card h4{
  font-weight: 600;
  color: black;
}

.container .card p{
  padding: 0 1rem;
  font-size: 16px;
  font-weight: 300;
  color: black;
}
.container .card a {
  font-weight: 500;
  text-decoration: none;
  color: #009ea0;
}

.pedircita {
  position: absolute;
  background-color: rgb(245,245,245);
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  border-radius: 50px;
  padding: 20px;
  pointer-events: all;
  cursor: pointer;
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 6%), 0 6px 40px 0 rgb(0 0 0 / 16%);
  font-size: 20px;
  color: black;
  text-decoration: none;
}
.pedircita:hover {
  background-color: rgb(230,230,230);
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.12), 0 8px 50px 0 rgba(0, 0, 0, 0.22);
  transform: translate(-50%, -50%) scale(1.05);
}
.center{
  width: 75%;
  margin: 0px auto;
}

#footer{
  background: rgb(230,230,230);
  width: 100%;
}
.unstyled {
  color: black;
  list-style: none inside;
}
.p-1{
  padding-top: 10px;
}
.bottomFooter{
  background: rgb(200,200,200);
  padding: 1rem;
}
.clearfix{
  clear:both;
  float:none;
}
textarea{
  resize: vertical;
}
.cookie{
  position: fixed;
  bottom: 0;
  background: rgb(0,156,160);
  cursor: pointer;
  right: 0;
  padding: .8em;
  border-radius: 20px 0 0 0;
  color: white;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 6%), 0 2px 32px 0 rgb(0 0 0 / 16%);
}

/*RESPONSIVE*/

@media (max-width: 1488px){
  .center{
    width: 85%;
  }
}

@media(max-width: 1335px){
  .center{
    width: 95%;
  }
}
@media(max-width: 925px){
  .map{
    border-radius: 0 0 50px 50px;
  }
  .googleMap{
    width: 100%;
  }
  #myMap{
    height: 400px;
    width: 100%;
  }
  .map .map-location{
    width: 100%;
    text-align: center;
  }
  .map .mapContent{
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    justify-content: space-around;
  }
  .map .mapSection{
    width: 30%;
  }
}
@media (max-width: 500px){
  .pedircita {
    top:80%;
    padding: 10px;
  }
}
@media (max-width: 372px){
  .map .mapContent{
    display: flex;
    text-align: left;
    justify-content: center;
  }
  .map .mapSection{
    width: 100%;
  }
}
