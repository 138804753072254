.service {
    display: flex;
    align-items: flex-end;
    color: white;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    padding: 10rem 0 0;
    max-width: 35ch;
    border-radius: 0.5rem;
    overflow: hidden;
    margin: 20px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);

    transition: transform 500ms ease;
}

.service:hover,
.service:focus-within {
    transform: scale(1.05);
}

.service-content {
    --padding: 1.5em;
    padding: var(--padding);
    background: linear-gradient(hsl(0 0% 0% / 0), hsl(20 0% 0% / 0.3) 20%, hsl(0 0% 0% / 1));
}

@media (hover) {
    .service-content {
        transform: translateY(50%);
        transition: transform 500ms ease;
    }

    .service:hover .service-content,
    .service:focus-within .service-content {
        transform: translateY(0);
        transition-delay: 500ms;
    }

    .service:focus-within .service-content {
        transition-duration: 0ms;
    }

    .service-content>*:not(.service-title) {
        opacity: 0;
        transition: opacity 500ms linear;
    }

    .service:hover .service-content>*:not(.service-title),
    .service:focus-within .service-content>*:not(.service-title) {
        opacity: 1;
        transition-delay: 1000ms;
    }

    .service-title::after {
        transform: scaleX(0);
    }
}



.service-title {
    position: relative;
    width: max-content;
}

.service-title::after {
    content: "";
    position: absolute;
    height: 4px;
    left: calc(var(--padding) * -1);
    bottom: -2px;
    width: calc(100% + var(--padding));
    background: rgb(0, 158, 161);
    transform-origin: left;

    transition: transform 500ms ease;
}

.service:hover .service-title::after,
.service:focus-within .service-title::after {
    transform: scaleX(1);
}

.service-body {
    color: rgb(255, 255, 255 / 0.85);
}
input[type="submit"]{
    color: black;
    margin: 20px;
    width: 30%;
}
.learn-more{
    color: white;
}
.learn-more, input[type="submit"] {
    cursor: pointer;
    display: inline;
    text-decoration: none;
    border: 1px solid rgb(0, 158, 161);
    background: transparent;
    padding: 0.5em 1.25em;
    border-radius: 0.25em;
}

.learn-more:hover,
.learn-more:focus,  input[type="submit"]:hover {
    background-color: rgba(0, 158, 161);
    color: white;
}

@media (prefers-reduced-motion: reduce) {
    *, *::before, *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}