/* -- upper header -- */
.upper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  width: 100%;
  background: rgba(255, 174, 38, 0.9);
}

.upperCollapse {
  display: none;
}

.flex-start {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: start;
  align-items: center;
  margin-right: 2rem;
}

.flex-end {
  justify-self: end;
}

.p-1 {
  padding-right: 8px;
}
.activelink{
  border-bottom: 2px solid #3cb2d3;
}

.text-dec-n {
  color: black;
  text-decoration: none;
}

.facebook {
  color: #0866FB; /* Icon color */
  border-radius: 50%;
  padding: 10px;
  font-size: 28px
}

.d-none {
  display: none;
}

/* -- header -- */
.navbar {
  position: fixed;
  width: 100%;
  background: rgba(245, 245, 245, 0.75);
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  z-index: 1;
  transition: all 0.5s ease;
}

.navbar.active {
  top: 0;
  background: whitesmoke;
  transition: all 0.5s ease;
}

.navbar-logo {
  height: 80px;
  justify-self: start;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wha {
  background-color: rgba(13, 167, 72, 1);
  border-radius: 50px;
  padding: 10px 13px;
  color: #fff;
}
.wha:hover {
  background-color: rgba(13, 167, 72, 0.75);
}

.nav-links {
  color: black;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: rgba(0, 158, 161, 0.7);
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: black;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 1000px) {

  .upper {
    display: none;
  }

  .upperCollapse {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    text-align: justify;
    text-justify: inter-word;
    padding: .3rem;
    font-size: medium;
  }

  .navbar {
    top: 0;
  }

  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    max-height: 500%;
    overflow-x: auto;
    background: whitesmoke;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    background-color: rgb(235,235,235);
    text-align: center;
    padding: 1rem;
    width: 50%;
    display: table;
  }

  .nav-links:hover {
    background-color: rgba(0, 158, 161, 0.7);
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
  .wha-item{
    padding-bottom: 1.5rem;
  }
  .wha{
    padding: 12px 65px;
  }

  .fa-times {
    color: black;
    font-size: 2rem;
  }
}